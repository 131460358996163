<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="90px"
      class="ruleForm lighter"
    >
      <center-title title="我的发票"></center-title>
      <div class="form-title bold mb20">发票申请</div>
      <el-form-item label="结算编号：">
        <el-input v-model="ruleForm.settle_sn" disabled></el-input>
      </el-form-item>
      <el-form-item label="发票类型： " prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择发票类型">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票内容：">
        <router-link :to="`/invoiceall?id=${ruleForm.settle_id}`" class="el-button whtie submit">商品明细</router-link>
      </el-form-item>
<!--      <el-form-item label="发票内容：" prop="bill_content">-->
<!--        <el-radio-group v-model="ruleForm.bill_content">-->
<!--          <el-radio label="商品明细"></el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="抬头类型：" prop="is_company">-->
<!--        <el-radio-group v-model="ruleForm.is_company">-->
<!--          &lt;!&ndash; <el-radio label="个人"></el-radio> &ndash;&gt;-->
<!--          <el-radio label="企业单位"></el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
      <el-form-item label="更多内容：">
        <el-input
          v-model="ruleForm.remarks"
          placeholder="请填写备注、地址等（非必填）"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="ruleForm.is_company == '企业单位' ? '企业名称：' : '个人名称：'"
        prop="company_name"
      >
        <el-input
          v-model="ruleForm.company_name"
          :placeholder="`请填写${
            ruleForm.is_company == '企业单位' ? '企业名称：' : '个人名称'
          }`"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="企业税号："
        prop="tax_sn"
        v-if="ruleForm.is_company == '企业单位'"
      >
        <el-input
          v-model="ruleForm.tax_sn"
          placeholder="请填写企业税号"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票总额：">
        <div class="price-box">
          <span class="primary mr10">{{ ruleForm.entry_account_money }}</span
          >元
        </div>
      </el-form-item>
      <div class="form-title bold mb20">收票地址</div>
      <el-form-item label="联系姓名：" prop="contact">
        <el-input
          v-model="ruleForm.contact"
          maxlength="25"
          placeholder="请输入联系姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" prop="telephone">
        <el-input
          v-model="ruleForm.telephone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="地址信息：" prop="region">
        <el-cascader
          :options="areaOptions"
          v-model="ruleForm.region"
          placeholder="请选择省/市/区/街道"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="详细地址：" prop="address">
        <el-input
          type="textarea"
          rows="3"
          maxlength="120"
          v-model="ruleForm.address"
          placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息
详细地址长度需要在5-120个汉字或字符，不能包含表情符号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <div class="row mt20">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { vxRule } from "@/utils/validator";
import CenterTitle from "../components/CenterTitle";
// import { regionData } from "element-china-area-data";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      ruleForm: {
        settle_sn: "",
        type: "",
        // bill_content: "商品明细",
        is_company: "企业单位",
        remarks: "",
        company_name: "",
        tax_sn: "",
        contact: "",
        telephone: "",
        region: "",
        address: "",
        entry_account_money: "",
        settle_id: "",
      },
      options: [
        {
          value: "1",
          label: "普通发票",
        },
        {
          value: "2",
          label: "增值税发票",
        },
      ],
      num: 1,
      rules: {
        address: vxRule(
          true,
          (val) => {
            return {
              result: val.length <= 120 && val.length >= 5,
              errMsg: "长度在 5-120 个汉字或字符",
            };
          },
          "blur",
          "请输入详细地址"
        ),
        contact: vxRule(true, null, "blur", "请填写收货人姓名"),
        telephone: vxRule(true, "Mobile", "blur", "请填写手机号码"),
        region: vxRule(true, null, "change", "请选择省/市/区/街道"),
        type: vxRule(true, null, "change", "请选择发票类型"),
        // bill_content: vxRule(true, null, "blur", "请选择发票内容"),
        is_company: vxRule(true, null, "change", "请选择抬头类型"),
        company_name: vxRule(true, null, "blur", "请填写名称"),
        tax_sn: vxRule(true, null, "blur", "请填写企业税号"),
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      areaOptions: [],
    };
  },
  computed: {},
  methods: {
    getAllAdressList() {
      this.$api.getAllAdressListApi().then((res) => {
        this.areaOptions = res.data;
      });
    },
    getInvoiceDetail(param) {
      this.$api.getInvoiceDetailApi(param).then((res) => {
        this.ruleForm.company_name = res.data.company_name;
        this.ruleForm.tax_sn = res.data.credit_code;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let value = this.ruleForm.is_company == "企业单位" ? 1 : 0;
          this.ruleForm.is_company = value;
          let { region } = this.ruleForm;
          this.$api
            .billApplyApi({
              ...this.ruleForm,
              province_id: region[0],
              city_id: region[1],
              district_id: region[2],
            })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  onClose: () => {
                    this.$router.back();
                  },
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.getAllAdressList();
    let query = this.$route.query;
    this.ruleForm.settle_id = query.id;
    this.ruleForm.settle_sn = query.settle_sn;
    this.ruleForm.entry_account_money = query.entry_account_money;
    this.getInvoiceDetail({"id": query.id});
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-form {
  width: 388px;

  .el-form-item__label {
    &::before {
      margin-right: 0 !important;
    }
  }

  .el-cascader {
    width: 100%;
  }

  .el-input__inner,
  .el-textarea__inner {
    resize: none;
    border-radius: 2px;
  }

  .el-radio {
    margin-right: 10px;

    .el-radio__input {
      &.is-checked {
        & + .el-radio__label {
          border-color: $-color-primary;
          color: $-color-primary;
        }
      }

      .el-radio__inner {
        display: none;
      }

      & + .el-radio__label {
        height: 30px;
        background: #ffffff;
        border: $-solid-border;
        padding: 7px 0px;
        width: 100px;
        display: inline-block;
        text-align: center;
        color: $-color-lighter;
        border-radius: 4px;
      }
    }
  }

  .price-box,
  .el-input__icon,
  .el-input__inner {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }

  .el-select {
    width: 100%;
  }

  .el-input {
    &.is-disabled {
      .el-input__inner {
        background-color: transparent;
        color: $-color-normal;
      }
    }

    .el-input__inner {
      border-color: $-color-border;
    }
  }

  .price-box {
    border: $-solid-border;
    border-radius: 4px;
    padding-left: 15px;
    display: inline-block;
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 20px;
  }
}
</style>
