<template>
  <div class="sales">
    <navtop
      :navList="navList"
      @changeCurrent="
        (e) => {
          afterSaleList = [];
          queryType = navValue[e];
          resetList(1);
        }
      "
    ></navtop>
    <!-- <div class="row-end mb20">
      <div class="serach-box">
        <el-input placeholder="输入商品标题进行搜索" v-model="input">
          <template slot="append">查询</template>
        </el-input>
      </div>
    </div> -->
    <el-form ref="form" :inline="true" :model="ruleForm" label-width="0px">
      <div class="row-between">
        <div class="row">
          <el-form-item>
            <el-input v-model="ruleForm.order_sn" placeholder="订单号搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="ruleForm.spu" placeholder="SPU搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="ruleForm.date"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="resetList(1)"
            >筛选</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="sales-list lighter" v-if="afterSaleList.length">
      <div class="sales-item th row bg-center">
        <div class="item row-center">订单编号</div>
        <div class="item row-center">订单商品</div>
      </div>

      <div
        class="sales-item row"
        v-for="(item, index) in afterSaleList"
        :key="index"
      >
        <div class="item row-center normal">
          {{ item.order_sn }}
        </div>
        <div class="item row">
          <div class="pro-list row" v-if="queryType == 'normal'">
            <router-link
              :to="`/salesapply?order_id=${item.order_id}&item_id=${items.item_id}`"
              class="pro-item pr pb10"
              v-for="(items, indexs) in item.order_goods"
              :key="indexs"
            >
              <el-image
                class="item-img"
                :src="items.image"
                fit="cover"
              ></el-image>
              <span class="xs row-center cursor">申请</span>
            </router-link>
          </div>
          <div class="pro-list row" v-else>
            <div
              class="pro-item pr"
              :class="{ pb10: queryType == 'normal' }"
              v-for="(items, indexs) in item.order_goods"
              :key="indexs"
            >
              <el-image
                class="item-img"
                :src="items.image"
                fit="cover"
              ></el-image>
            </div>
          </div>
        </div>
        <div class="item row-center" @click="contactService()">
          <img src="@/assets/icon/icon-service2.png" alt="" class="mr10" />
          联系客服
        </div>
        <div class="item muted xs row-center" v-if="queryType == 'normal'">
          {{ item.create_time || item.time }}
        </div>
        <div
          class="item muted xs column-center btn-box"
          v-if="queryType != 'normal'"
        >
          <div
            v-if="queryType == 'apply'"
            class="btn cursor"
            @click="cancelRepply(item.after_sale.after_sale_id)"
          >
            撤销申请
          </div>
          <router-link
            v-if="
              queryType == 'apply' ||
              queryType == 'finish' ||
              queryType == 'refuse' ||
              queryType == 'have'
            "
            :to="`/salesdetail?id=${item.after_sale.after_sale_id}`"
            class="btn"
            >查看详情</router-link
          >
          <div
            class="btn cursor"
            v-if="
              item.after_sale.refund_need_dev == 0 &&
              item.after_sale.status == 1
            "
            @click="refundNeedDev(item.after_sale.after_sale_id)"
          >
            申请平台介入
          </div>
          <div class="price" v-if="item.after_sale.refund_need_dev == 1">
            介入中（平台）
          </div>
          <div class="price" v-if="item.after_sale.refund_need_dev == 2">
            同意退款（平台）
          </div>
          <div class="price" v-if="item.after_sale.refund_need_dev == 3">
            不同意退款（平台）
          </div>
        </div>
      </div>
    </div>
    <el-empty
      description="暂无维修订单"
      v-if="!afterSaleList.length"
    ></el-empty>
    <div class="row-end pagination">
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
        v-if="afterSaleList.length"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import NavTop from "../components/NavTop";
import MakePagination from "@/components/MakePagination";
export default {
  name: "",
  props: {},
  components: {
    navtop: NavTop,
    "center-title": CenterTitle,
    "make-pagination": MakePagination,
  },
  data() {
    return {
      // navList: ["申请返修", "申请退换货", "退款明细", "维修明细"],
      navList: ["售后申请", "处理中", "已处理", "已拒绝", "总申请"],
      navValue: ["normal", "apply", "finish", "refuse", "have"],
      afterSaleList: [], //售后列表
      input: "", //关键词搜索
      orderObject: {},
      queryType: "normal",
      page_no: 1,
      page_size: 10,
      ruleForm: {
        order_sn: "",
        spu: "",
        date: [],
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClose() {
      if (!this.ruleForm.date) {
        this.resetList(1);
      }
    },
    cancelRepply(id) {
      this.$api.cancelAfterSaleApi({ id }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
            onClose: () => {
              this.getAfterSaleList();
            },
          });
        }
      });
    },
    contactService() {
      window.location.href = "https://work.weixin.qq.com/kfid/kfcbb9fff861d74a981";
    },
    /**
     * @description 获取售后列表
     * @returns void
     */
    getAfterSaleList() {
      let { date, spu , order_sn} = this.ruleForm;
      this.$api
        .getAfterSaleListApi({
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          spu,
          order_sn,
          start_time: date && date[0],
          end_time: date && date[1],
        })
        .then((res) => {
          this.afterSaleList = res.data.list;
          this.orderObject = res.data;
        });
    },
    refundNeedDev(id) {
      this.$confirm("是否需要申请平台介入", "", {
        distinguishCancelAndClose: true,
        confirmButtonText: "需要",
        cancelButtonText: "取消",
      }).then(() => {
        this.$api.refundNeedDevApi({ id }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
              onClose: () => {
                this.resetList(1);
              },
            });
          }
        });
      });
    },
    resetList(e) {
      this.page_no = e;
      this.getAfterSaleList();
    },
  },

  created() {
    this.getAfterSaleList();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
// /deep/ .el-form {
  // .el-range__close-icon {
    // display: none !important;
  // }
// }

.btn-box {
  padding: 10px 0 !important;
  width: 150px;

  .btn {
    padding: 5px;
    border: $-solid-border;
    border-radius: 3px;
    margin: 5px 0;
  }
}

.sales {
  padding-bottom: 40px;

  .serach-box {
    width: 368px;

    /deep/ .el-input {
      .el-input-group__append {
        border-radius: 2px;
        background-color: $-color-border;
        color: $-color-lighter;
        width: 107px;
        padding: 0;
        text-align: center;
        border-width: 2px;
        font-size: 18px;
      }

      .el-input__inner {
        border-width: 2px;
        font-size: 16px;
        border-radius: 2px;
      }
    }
  }

  .sales-list {
    margin-bottom: 35px;

    .sales-item {
      align-items: inherit;

      &:not(.th) {
        border-bottom: $-solid-border;

        .item {
          border-left: $-solid-border;

          &:last-child {
            border-right: $-solid-border;
          }
        }
      }

      .item {
        padding: 15px 0;

        &:nth-child(1) {
          width: 26.5%;
        }

        &:nth-child(2) {
          padding: 0 15px;
          width: 36.5%;
        }

        &:nth-child(3) {
          width: 18.5%;

          img {
            width: 19px;
          }
        }

        &:nth-child(4) {
          width: 18.5%;
          padding: 0 3%;
          text-align: center;
        }

        .pro-list {
          padding: 15px 0;
          width: 100%;
          overflow: auto;

          .pro-item {
            margin-right: 10px;

            &.pb10 {
              padding-bottom: 10px;
            }

            &:last-child {
              margin-right: 0;
            }

            .item-img {
              width: 52px;
              height: 52px;
              display: block;
            }

            span {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 45px;
              height: 20px;
              background-color: #e8f6ff;
              color: $-color-primary;
              border: 1px solid $-color-primary;
              border-radius: 3px;
              z-index: 2;
            }
          }
        }
      }
    }
  }
}
</style>
