<template>
  <div>
    <span>{{ countDownTime }}</span>
  </div>
</template>

<script>
let timer = null;
import moment from "moment";
export default {
  props: {
    startTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      countDownTime: "",
      time: moment(this.startTime * 1000).format("YYYY-MM-DD HH:mm"),
    };
  },
  created() {
    clearInterval(timer);
    timer = setInterval(() => {
      this.countDownTime = this.countDown();
    }, 1000);
  },
  methods: {
    countDown() {
      const seconds = moment(
        moment(this.startTime * 1000).format("YYYY-MM-DD HH:mm")
      ).diff(new Date(), "seconds");
      if (seconds <= 0) {
          clearInterval(timer);
          return;
      }
      const second = (seconds % 60).toString().padStart(2, 0);
      const minutes = (seconds - second) / 60;
      const minute = (minutes % 60).toString().padStart(2, 0);
      //   const hours = ((minutes - minute) / 60).toString().padStart(2, 0);
      const hours = ((minutes - minute) / 60).toString().padStart(2, 0);
      //   const hour = hours % 24;
      //   const day = (hours - hour) / 24;
      const res = hours + ":" + minute + ":" + second;
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  letter-spacing: 2px;
}
</style>
