<template>
  <div class="">
    <center-title
      :title="
        acountMode == 'add'
          ? '子账号新增'
          : acountMode == 'edit'
          ? '子账号修改'
          : '子账号详情'
      "
      :border="false"
    >
    </center-title>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="162px"
      class="ruleForm"
    >
      <el-form-item label="用户头像" prop="avatar">
        <el-upload
          style="width: 90px; height: 90px"
          :action="`${download}/file/formimage`"
          class="info-avatar"
          :disabled="acountMode == 'View'"
          :headers="{ token: $store.getters.userInfo.token }"
          :on-success="successUpload"
          :show-file-list="false"
        >
          <el-image
            class="avatar row-center"
            :src="userAvatar || ruleForm.avatar"
            fit="cover"
          >
            <div slot="error" class="image-slot" v-if="acountMode == 'add'">
              <i class="el-icon-camera"></i>
            </div>
          </el-image>
        </el-upload>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile" class="mb30">
        <el-input
          v-model="ruleForm.mobile"
          placeholder="请输入手机号码"
          :disabled="acountMode == 'View'"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="账号密码"
        prop="password"
        class="mb30"
        v-if="acountMode != 'View'"
      >
        <el-input
          v-model="ruleForm.password"
          :placeholder="acountMode == 'Edit' ? '默认不修改' : '请输入账号密码'"
        ></el-input>
      </el-form-item>
      <el-form-item label="账号昵称" prop="nickname" class="mb30">
        <el-input
          v-model="ruleForm.nickname"
          placeholder="请输入账号昵称"
          :disabled="acountMode == 'View'"
        ></el-input>
      </el-form-item>
      <el-form-item label="真实姓名" prop="true_name" class="mb30">
        <el-input
          v-model="ruleForm.true_name"
          placeholder="请输入真实姓名"
          :disabled="acountMode == 'View'"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户类型" prop="group_id" class="mb30">
        <el-select
          v-model="ruleForm.group_id"
          placeholder="请选择账号身份"
          :disabled="acountMode == 'View'"
        >
          <el-option label="主账号" :value="1" v-if="ruleForm.group_id == 1">
          </el-option>
          <el-option
            v-for="(item, index) in filteredTypeList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
   
      <!-- <el-form-item label="部门" prop="cost" class="mb30">
        <el-input v-model="ruleForm.mobile1" placeholder="部门" :disabled="acountMode == 'View'"></el-input>
      </el-form-item> -->
 <DIV v-if="this.$store.getters.userInfo.company_id == 57">
     <el-form-item label="部门" prop="department_id" class="mb30">
        <el-select
           v-model="ruleForm.department_id"
        placeholder="请选择账号部门"
       :disabled="acountMode == 'View'"
     >
           <el-option
              label="主账号"
             :value="1"
             v-if="ruleForm.department_id == 1"
        >
           </el-option>
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

     

     

       <!-- <el-form-item label="限购额度" class="mb30">
          <el-input
           v-model="ruleForm.quota_id"
           placeholder="请输入限购额度"
         :disabled="acountMode == 'View'"
        ></el-input>
       </el-form-item> -->
    </DIV>

    <el-form-item label="限购品类" class="mb30"> 
          <!-- <el-input
            v-model="ruleForm.limit_cate"
            placeholder="请选择限购品类"
            :disabled="acountMode == 'View'"
             @click.native="getTree"
          ></el-input> -->

          <div class="inline-elements">

            
              <el-select v-model="ruleForm.limit_cate" placeholder="请选择限购品类" multiple  @remove-tag="RemoveLimitCateTag" style="margin-top: 10px;">
                  <el-option  style="height:auto;background-color: #fff;"
                    v-for="(item, index) of LimitCateData"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                    @click.native="LimitCateOption(item.value)" 
                  ></el-option>
                </el-select>

              <el-button type="primary" @click="LimitdialogVisible = true">选择限购品类</el-button> 
          </div>



 
          <el-dialog
                title="请选择限购品类"
                :visible.sync="LimitdialogVisible"
                width="80%"
                :before-close="LimithandleClose">
                <span>
                  <el-card class="box-card"> 
                    <div class="sync-dialog__div">
                        <el-tree ref="LimitCateTree" :data="data" :props="defaultProps" node-key="value" show-checkbox   @node-click="handleNodeClick"   default-expand-all
                  :expand-on-click-node="false" 
                  @check="currentChecked" 
                  :render-content="renderContent">
                      </el-tree>
                      </div>
                  </el-card>
                </span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="limit_money_tree_canel()" >取 消</el-button>
                  &nbsp; &nbsp; 
                  <el-button type="primary" @click="limit_money_tree_ok()">确 定</el-button>
                </span>
           </el-dialog>


          <el-dialog
                title="请输入限购额度"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
                <span>
                  <el-radio v-model="limit_type" label="1">月份限制</el-radio>
                  <el-radio v-model="limit_type" label="2">年份限制</el-radio>
                  <el-input v-model="limit_money" placeholder="请输入限购额度"></el-input>
                </span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false" >取 消</el-button>
                  &nbsp; &nbsp; 
                  <el-button type="primary" @click="limit_money_ok()">确 定</el-button>
                </span>
           </el-dialog>

        </el-form-item>

      <el-form-item
        label="管控公司"
        prop="checkList"
        class="mb30"
        v-if="ruleForm.group_id != 2"
      >
        <el-checkbox-group
          v-model="ruleForm.checkList"
          :disabled="acountMode == 'View'"
        >
          <el-checkbox
            :label="item.id"
            v-for="(item, index) in companyGroupList"
            :key="index"
            >{{ item.company_name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="成本单元" prop="cost_unit" class="mb30" v-if="userInfo.company_id == 61">
        <el-input
          v-model="ruleForm.cost_unit"
          placeholder="请输入成本单元"
          :disabled="acountMode == 'View'"
        ></el-input>
      </el-form-item>

      <el-form-item v-if="acountMode != 'View'">
        <el-button
          type="primary"
          class="white md bg-primary"
          @click="submitForm('ruleForm')"
        >
          {{ acountMode == "add" ? "新增" : "保存修改" }}
        </el-button>
        <el-button
          class="reset bg-center white md"
          @click="resetForm('ruleForm')"
          v-if="acountMode != 'Edit'"
          >重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import { vxRule } from "@/utils/validator";
import Cache from "@/utils/cache";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      SaveLimitCateData:[],
      checkedNodes:[],
      LimitCateData:[],
      limit_money:0,
      limit_type:"0",
      LimitdialogVisible:false,
      dialogVisible: false,
      data:null,
      curr_data:null,
      curr_store:null,
      defaultProps: {
          children: 'children',
          label: 'label'
      },
      ruleForm: {
        mobile: "", //手机号码
        password: "", //密码
        nickname: "", //昵称
        true_name: "", //真实姓名
        group_id: "", //账号身份
        avatar: "",
        company_auth_id: "",
        checkList: [],
        cost_unit: ""
      },
      rules: {
        mobile: vxRule(true, "Mobile", "blur", "请输入手机号码"),
        password: vxRule(true, "Password", "blur", "请输入密码"),
        nickname: vxRule(true, null, "blur", "请输入昵称"),
        true_name: vxRule(true, null, "blur", "请输入真实姓名"),
        group_id: vxRule(true, null, "change", "请选择账号身份"),
        cost: vxRule(true, null, "cost", "请输入成本"),
        // quota_category: vxRule(true, null, "quota_category", "请输入限购品类"),
        // quota_amount: vxRule(true, null, "quota_amount", "请输入限购金额"),
        checkList: vxRule(true, null, "change", "请选择管控公司"),
      },
      value: [],
      download: process.env.VUE_APP_DOWNLOAD_URL,
      typeList: [
        {
          label: "科室科员",
          value: 2,
        },
        {
          label: "科室主任",
          value: 3,
        },
        {
          label: "采购",
          value: 4,
        },
        // 南宁招标需求只针对德安医疗显示 company_id = 57
        {
          label: "财务",
          value: 5,
        }
      ],
      groupList: [
        {
          label: "内科",
          value: 2,
        },
        {
          label: "外科",
          value: 3,
        },
        {
          label: "妇科",
          value: 4,
        },
        {
          label: "儿科",
          value: 5,
        },
        {
          label: "心内科",
          value: 6,
        },
        {
          label: "呼吸科",
          value: 7,
        },
        {
          label: "血液科",
          value: 8,
        },
        {
          label: "消化科",
          value: 9,
        },
        {
          label: "内分泌科",
          value: 10,
        },
        {
          label: "胃肠外科",
          value: 11,
        },
        {
          label: "肝胆科",
          value: 12,
        },
        {
          label: "腹壁疝科",
          value: 13,
        },
        {
          label: "大肠科",
          value: 14,
        },  {
          label: "骨科",
          value: 15,
        },
        {
          label: "关节科",
          value: 16,
        },
        {
          label: "脊柱外科",
          value: 17,
        },
      ],
      acountMode: "add",
      userAvatar: "",
      companyGroupList: [],
      userInfo: {}, //用户信息
    };
  },
  computed: {
    filteredTypeList() {
      // 只在company_id为57时显示总经办
      return this.typeList.filter(item => {
        if (item.value === 5 && this.userInfo.company_id !== 57) {
          return false;
        }
        return true;
      });
    }
  },
  watch: {},
  methods: {
    setSaveLimitCateData(){
      let getCheckedNodes = this.$refs.LimitCateTree.getCheckedNodes();
      var save_data       = new Array();  
      if(getCheckedNodes.length > 0) {
        for (var i = 0; i < getCheckedNodes.length; i++) {
          if(typeof getCheckedNodes[i].level !=="undefined" && getCheckedNodes[i].level == 3){
               var save_obj               = new Object();
               save_obj.value             = getCheckedNodes[i].value;
               save_obj.limit_money       = getCheckedNodes[i].limit_money;
               save_obj.limit_type        = getCheckedNodes[i].limit_type;
              save_data.push(save_obj);
          }
        }
      }

      this.SaveLimitCateData = save_data;
    },
    LimitCateOption(item){
        if(this.ruleForm.limit_cate.indexOf(item) != -1){ //找到选中
          this.$refs.LimitCateTree.setChecked(item, true, false);
        }else{
          this.$refs.LimitCateTree.setChecked(item, false, false);//没找到取消
        }
        this.setSaveLimitCateData(); 
    },
    RemoveLimitCateTag(item){
      this.$refs.LimitCateTree.setChecked(item, false, false);//取消选中
      this.setSaveLimitCateData(); 
    },
    currentChecked(nodeObj, SelectedObj){
      // var checkedNodes = new Array();  // 这是选中的节点数组
      // if(SelectedObj.checkedNodes.length > 0) {
      //   for (var i = 0; i < SelectedObj.checkedNodes.length; i++) {
      //     if(typeof SelectedObj.checkedNodes[i].level !=="undefined" && SelectedObj.checkedNodes[i].level == 3){
      //       checkedNodes.push(SelectedObj.checkedNodes[i]);
      //     }
      //   }
      // }
      // this.checkedNodes = checkedNodes;
      //console.log(this.checkedNodes);
    },
    getTree() {
      this.LimitdialogVisible = true;
    },
    getCompanyTreeList(){
      this.$api.getCompanyTreeList().then((res) => {
        this.data = res.data;
      });
    },
    LimithandleClose(){
       this.LimitdialogVisible = false;
       this.LimitCateData      = [];
       this.SaveLimitCateData  = []; 
       this.ruleForm.limit_cate =[];
       this.$refs.LimitCateTree.setCheckedKeys([]); //取消选中所有节点
    },
    limit_money_tree_canel(){
       this.LimitdialogVisible = false;
       this.LimitCateData      = [];
       this.SaveLimitCateData  = []; 
       this.ruleForm.limit_cate =[];
       this.$refs.LimitCateTree.setCheckedKeys([]); //取消选中所有节点
    },
    limit_money_tree_ok(){
      let getCheckedNodes = this.$refs.LimitCateTree.getCheckedNodes();
      var checkedNodes = new Array();  // 这是选中的节点数组
      var _data = new Array();
      var save_data = new Array();  

      if(getCheckedNodes.length > 0) {
        for (var i = 0; i < getCheckedNodes.length; i++) {
          if(typeof getCheckedNodes[i].level !=="undefined" && getCheckedNodes[i].level == 3){
               var obj                    = new Object();
               obj.value                  = getCheckedNodes[i].value;
               obj.label                  = getCheckedNodes[i].label;
               var save_obj               = new Object();
               save_obj.value             = getCheckedNodes[i].value;
               save_obj.limit_money       = getCheckedNodes[i].limit_money;
               save_obj.limit_type        = getCheckedNodes[i].limit_type;
               checkedNodes.push(getCheckedNodes[i]);
              _data.push(obj);
              save_data.push(save_obj);
          }
        }
      }

      if(_data.length>0){
          var __data = new Array();
          for (var i = 0; i < _data.length; i++) {
            __data.push(_data[i].value);
          }
          this.ruleForm.limit_cate = __data;
      }
      this.LimitCateData      = _data;
      this.SaveLimitCateData  = save_data; 
      this.LimitdialogVisible = false;
      console.log(this.SaveLimitCateData);
    },  
    limit_money_ok(){
      this.curr_data.limit_money = this.limit_money;
      this.curr_data.limit_type  = this.limit_type;
      if(typeof this.curr_data.old_label ==="undefined"){
        this.curr_data.old_label   = this.curr_data.label;
        if(this.curr_data.limit_money.length>0){
          var limit_pre_str = "";
          if(this.curr_data.limit_type == 1){
            limit_pre_str = "月份";
          }else if(this.curr_data.limit_type == 2){
            limit_pre_str = "年份";
          }
           this.curr_data.label       = this.curr_data.label+"(限制"+limit_pre_str+"额度:"+this.curr_data.limit_money+")";
        }else{
          this.curr_data.label        = this.curr_data.label;
        }
      }else{
        if(this.curr_data.limit_money.length>0){
          var limit_pre_str = "";
          if(this.curr_data.limit_type == 1){
            limit_pre_str = "月份";
          }else if(this.curr_data.limit_type == 2){
            limit_pre_str = "年份";
          }
          this.curr_data.label       = this.curr_data.old_label+"(限制"+limit_pre_str+"额度:"+this.curr_data.limit_money+")";
        }else{
          this.curr_data.label       = this.curr_data.old_label;
        }
      }
      this.dialogVisible           = false;
    },  
    renderContent(h, { node, data, store }) {
        if(data.level == 3){
            return (
            <span style="flex: 1;display: flex;align-items: center;justify-content: space-between;font-size: 14px;padding-right: 8px;">
              <span>{node.label}</span>
              <span>
                <el-button  style="margin-bottom:10px" size="mini" type="text" on-click={ () => this.limit_cate_num(store,data) }>限购额度</el-button>
              </span>
            </span>)
        }else{
            return (
              <span style="flex: 1;display: flex;align-items: center;justify-content: space-between;font-size: 14px;padding-right: 8px;">
              <span>{node.label}</span>
            </span>
            );
        }
      },
      limit_cate_num(store,data) {
        this.curr_store    = store;
        this.curr_data     = data;
        this.limit_money   = data.limit_money;
        this.limit_type    = data.limit_type;
        this.dialogVisible = true;
        },
        handlecheckNodeClick(data){
          alert(data);
        },
      handleNodeClick(data) {
        
      },
      LimithandleNodeClick(data) {
          console.log(data);
      },
    submitForm(formName) {

       var json = JSON.stringify(this.SaveLimitCateData);

       this.ruleForm.limit_cate_json = json;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let api =
            this.acountMode == "add"
              ? this.$api.addSubAccountApi
              : this.$api.EditSubAccountDetailApi;

          api({
            ...this.ruleForm,
            user_id: this.ruleForm.id,
            avatar: this.userAvatar || this.ruleForm.avatar,
            company_auth_id:
              this.ruleForm.group_id == 2
                ? ""
                : this.ruleForm.checkList.join(","),
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
                onClose: () => {
                  this.$router.go(-1);
                },
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getSubAccountDetail() {
      this.$api
        .getSubAccountDetailApi({
          user_id: this.$route.query.id,
        })
        .then((res) => {
          this.ruleForm = res.data;
          // console.log(res.data, 'res.data')
          this.ruleForm.checkList = res.data.company_auth_list.map((el) => {
            return el.id;
          });
          // console.log(this.ruleForm);
        });
    },
    successUpload(res) {
      let { code, data } = res;
      this.userAvatar = res.data.uri;
    },
    getCompanyGroup() {
      this.$api.getCompanyGroupApi().then((res) => {
        if (res.code == 1) {
          this.companyGroupList = res.data;
        }
      });
    },
  },
  created() {
    this.userInfo = Cache.get("userInfo")
    
    if (this.$route.name == "acountEdit") {
      this.acountMode = "Edit";
      this.rules.password = vxRule(false, "Password", "blur", "请输入密码");
    }
    if (this.$route.name == "acountView") {
      this.acountMode = "View";
    }
    if (this.acountMode == "Edit" || this.acountMode == "View") {
      this.getSubAccountDetail();
    }
    this.getCompanyGroup();
    this.getCompanyTreeList();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
}


.sync-dialog__div {
    height: 300px;
    overflow: auto;
}

.inline-elements {
  display: flex;
  align-items: center; /* 可选，用于垂直居中对齐 */
  justify-content: space-between; 
}

.inline-elements .el-select,
.inline-elements .el-button {
  margin-right: 10px; /* 可选，用于增加元素之间的间距 */
}

.inline-elements .el-button {
  flex-shrink: 0; /* 防止按钮被压缩 */
}

/deep/ .image-slot {
  font-size: 28px;
  color: #ccc;
}

.el-checkbox {
  height: 48px;
  line-height: 48px;
}

.info-avatar {
  width: 90px;
  height: 90px;
  margin-right: 25px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f5f7fa;
  border: 1px solid #f5f7fa;

  .avatar {
    width: 90px;
    height: 90px;
  }

  .tips {
    bottom: 0;
    height: 25px;
    left: 0;
    right: 0;
    line-height: 20px;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }
}

/deep/ .el-form {
  .el-select {
    width: 100%;
  }

  .el-button,
  .el-form-item__label,
  .el-input__inner {
    height: 48px;
    line-height: 48px;
    font-size: 16px;
  }

  .el-button,
  .reset {
    width: 150px;
    margin: 10px auto 0;
    padding: 0;
  }

  .reset {
    margin-left: 30px;
    border: none;
    background-color: $-color-border;
  }
}

.el-select-dropdown__item {
  font-size: 16px;
  height: 44px;
  line-height: 44px;
}


  /deep/ .el-tree {
        padding-top: 15px;
        padding-left: 10px;
        // 不可全选样式
        .el-tree-node {
          .is-leaf + .el-checkbox .el-checkbox__inner {
            display: inline-block;
          }
          .el-checkbox .el-checkbox__inner {
            display: none;
          }
        }
      }

</style>
